import config from "./config"
const limitTextLength = 14;
const common = {
    isLoggedIn: ()=> {
        if (localStorage.getItem("Authorization")) return true;
        return false;
    },
    isAdminLoggedIn: ()=> {
        if (localStorage.getItem("Admin-Authorization")) return true;
        return false;
    },
    isMember: ()=> {
        return localStorage.getItem("membershipStatus") ;
    },
    resetForm: (data) => {

        const {event, className} = data;
        const clearByEvent= (event)=>{
            event.classList.remove('was-validated');
            Array.prototype.slice.call(event.elements)
            .map((ele => {
            ele.classList.remove("is-invalid")
            if(ele.localName ==="input") {
                ele.value = "";
            }
            }))
        }
        if (event){
            clearByEvent(event.target);
        } 

        if (className){
            var forms = document.querySelectorAll('.'+ className);
            
            if(forms.length) clearByEvent(forms[0]);
            
            
        }
        
      },
    getUserId: ()=>{
        return localStorage.getItem('id');
    },
    getHeaders: ()=>{
        const  headers = config.headers;
        if (localStorage.getItem('Authorization')){
            headers['Authorization'] = localStorage.getItem('Authorization');
        }
        return headers;
    },
    limitTextLength:limitTextLength,
    getLimitText: (text, appendText, limit=limitTextLength)=>{
        let newText;
        if (text === undefined || text === null) {
            return text?.trim() || "";
        }

        if(text.length > limit) newText = text.slice(0, limit) +'..';
        else newText = text;

        if (appendText){
            return appendText + newText;
        }
        return newText;
    }
}

export default common;