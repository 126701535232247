import { useEffect, } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { useState } from "react";
import "../components/css/FavoriteAndContacted.css";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { SkeletonCommonListing } from "./SkeletonCommonListing";

import _ from 'lodash';
import ShareAndSaveButton from "./ShareAndSaveButton";
import config from "../utils/config";
import common from "../utils/common";


const FavoriteAndContacted = ({ isMembershipEnabled = false, IsFavorite = false }) => {

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const ownerType = 'owner'
  // const pageType = (ownerType);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [limit] = useState(6);
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(window.location.search)
  const [isLoading, setLoading] = useState(true)
  const [page, setPage] = useState(1);
  // const [userType, setUserType] = useState(queryParams.get("usertype") || ownerType)

  // const linkHandler = (userType)=>{
  //   setUserType(userType)
  //   setPage(1) // reset the page to 1 on link change
  // }
  async function getAllRecords() {
    setLoading(true)
    let url;
    if(IsFavorite){
      url = apiMapper.GET_FAVORITE + `?page=${page}&limit=${limit}`
    }
    else{
      url = apiMapper.GET_RECENT_CONTACTED + `?page=${page}&limit=${limit}`
    }
    const response = await fetchFunction.get(url, config.host, common.getHeaders());
    if(response?.status === 200){
      setLoading(false)
    }
    const finalresponse = response?.data?.data?.data || []
    const count = response?.data?.data?.totalCount;
    setTotalCount(count);
    setPageData(finalresponse);
  }

  useEffect(() => {
    getAllRecords()

  }, [page]);
  function capitalizeFirstLetter(string) {
    // Check if the string is null, undefined, or empty after trimming
      if (!string || typeof string !== "string" || string.trim() === "") {
          return ""; // Return an empty string if the input is null, undefined, or empty
      } else {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
  }

  if (!common.isLoggedIn()){
    navigate(1) // redirect to home page if not loggin
    return
  }

  return (
    <>
    <div>
      <Helmet>
        {/* Meta tags for SEO */}
        <meta name="description" content="Find rooms and apartments for rent at Eksarathi. Explore our listings and find the ideal accommodation for your needs." />
        <meta name="keywords" content="room for rent, apartment for rent, affordable housing, Eksarathi, Indian Roommates, Indian Rentals, Desi rentals, South Indian Rentals, North Indian Rentals, Short term rentals, Long Term Rentals, Furnished room, Unfurnished room, Desi Owners, Desi Tenants, Desi Roommates, Student Rentals, Indian Student Rentals, Telugu Roommates, Tamil Roommates, Nepali Rentals, Pakistani Rentals, Bangladeshi Rentals, roomshare, house share, sublets, apartment share" />
        <meta name="author" content="Eksarathi" />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Eksarathi - Search Affordable Rooms and Apartments for Rent in the USA" />
        <meta property="og:description" content="Explore our listings and find the ideal accommodation for your needs at Eksarathi." />
        <meta property="og:image" content="url-to-your-image.jpg" />
        <meta property="og:url" content="https://www.eksarathi.com/findrooms" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Eksarathi - Search Affordable Rooms and Apartments for Rent in the USA" />
        <meta name="twitter:description" content="Explore our listings and find the ideal accommodation for your needs at Eksarathi." />
        <meta name="twitter:image" content="url-to-your-image.jpg" />

        {/* Page title */}
        <title>Find Your Perfect Rental: Search Rooms & Apartments in the USA | Eksarathi</title>
      </Helmet>
      {/* Your component content */}
    </div>
      <div className="container-fluid min-vh-100 favoritelisting">
        <div className="container favoritelistingContainer d-flex flex-column justify-content-center align-items-center">
          <h1 className="heading-text">{IsFavorite ? "Your Favorites" : "Recently Contacted"}</h1>
            
            {/* -----Main---- */}
            <div className="col-12 col-md-8 main">
              <div className="container">
                <div className="row my-1" style={{ backgroundColor: "white" }}>
                  <div>{`Showing ${pageData.length === 0 ? 0:(page-1) * limit + 1}-${(page-1) * limit + pageData?.length} of over ${totalCount} ${IsFavorite ? "favorite" : "recently contacted"} listings.`} </div>
                  <div className="row sortinghead">
                    {/* {IsFavorite && <ul> */}
                      {/* <li><strong>sort By</strong></li> */}
                      {/* <li><Link to={IsFavorite ? "/favorites?usertype=owner" : "/recently-contact?usertype=owner"} className={userType.toLowerCase() === "owner" ? "sortingSubHeading":'' } onClick={()=> linkHandler('owner')}>Room</Link></li>
                      <li><Link to={IsFavorite ? "/favorites?usertype=tenant": "/recently-contact?usertype=tenant"} className={userType.toLowerCase() === "tenant" ? "sortingSubHeading":'' }  onClick={()=> linkHandler('tenant')}>Tenant</Link></li> */}
                    {/* </ul>} */}
                  </div>
                </div>
                {isLoading ? <SkeletonCommonListing/>:(pageData?.length === 0 ? (<div style={{margin:'10% 0', textAlign:'center', fontWeight:'bold'}}>No results found</div>) : (<>
                  {pageData?.map((ele) => {
                    
                    return (
                      <>
                        <div className="row mt-2 py-2 rounded-3 rounded-top-0 mb-2 border-2 shadow-sm " style={{ backgroundColor: "white" }}>
                          <div className="col-12 col-md-3 p-4 text-center">
                            {isMobile && <Link to={(ele?.adId?.userType === ownerType) ? ("/ownerproduct/" + ele?.adId._id) : ("/tenantproduct/" + ele?.adId._id)}>
                              <img
                                 src={ele?.adId?.photos[0] || 
                                 `/images/placeholder/${ele?.adId?.userType === "owner" ? 'owner' : 
                                 (ele?.adId?.userId?.gender === "male" ? 'tenantM' : 'tenantF')}.jpg`
                                 }
                               
                                alt="user image"
                                className="imagebgMobile" />
                            </Link>}
                            {/* change in deployment */}
                            {isDesktop && <Link to={(ele?.adId?.userType === ownerType) ? ("/ownerproduct/" + ele?.adId._id) : ("/tenantproduct/" + ele?.adId._id)}>
                              <img
                                 src={ele?.adId?.photos[0] || 
                                 `/images/placeholder/${ele?.adId?.userType === "owner" ? 'owner' : 
                                 (ele?.adId?.userId?.gender === "male" ? 'tenantM' : 'tenantF')}.jpg`
                                 }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>}
                            {/* {isDesktop && <Link to={(pageType === ownerType) ? ("/ownerproduct/" + ele?.adId._id) : ("/tenantproduct/" + ele?.adId._id)}>
                              <img
                              // change in deployment
                                 src={
                                  (!ele?.adId.photos[0] || ele?.adId.photos[0] === "")
                                    ? `/images/placeholder/male.jpeg`
                                    : ele?.adId.photos[0]
                                }
                                // src={
                                //   ele?.adId?.photos && ele?.adId?.photos.length > 0
                                //   ? `http://localhost:6167/${ele?.adId.photos[0]}`
                                //   : `/images/placeholder/${ele?.adId?.userId?.gender || "male"}.jpg`
                                // }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>} */}
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row maincontent">
                              <Link to={(ele?.adId?.userType === ownerType) ? ("/ownerproduct/" + ele?.adId._id) : ("/tenantproduct/" + ele?.adId._id)}>
                                <a href={null}>{(ele?.adId?.userType === ownerType) ? (`${(ele?.adId?.roomTypes === 'room-shared-2' ? '3 Sharing' : '') || (ele?.adId?.roomTypes === 'room-shared-1' ? '2 Sharing' : '') || (ele?.adId?.roomTypes === 'private-room' ? 'Private' : '')} Room in ${ele?.adId?.location?.address}`) : `Looking for room in ${ele?.adId?.location?.address}`}</a>
                              </Link>
                            </div>
                            <div className="row px-2 liststyle">
                              <ul className="listingdisplay my-2 px-4">
                                <li><span className="subheading">Room Type</span>: {ele?.adId?.roomTypes && ele?.adId?.roomTypes.length > 0 ?[
                                  ele?.adId?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? '3 Sharing' : '',
                                  ele?.adId?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? '2 Sharing' : '',
                                  ele?.adId?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private room' : '',
                                  ele?.adId?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                ].filter(Boolean).join(', '):'N/A'}</li>
                                {/* <li><span className="subheading">Location</span>: {capitalizeFirstLetter(ele?.adId?.city.name)}</li> */}
                                <li><span className="subheading">Name</span>: {ele?.adId?.userId?.name}</li>
                                <li><span className="subheading">{(ele?.adId?.userType === 'owner' ?'Available From' : 'Need From')}</span>: {moment(ele?.adId?.availableFrom).format('ll') || '--'}</li>
                                <li><span className="subheading">Rent</span>: {ele?.adId?.rent ? '$'+ele?.adId?.rent  : 'N/A'}</li>
                                {/* <li><span className="subheading">Offer For</span>: {ele?.adId?.offerFor}</li> */}
                                <li>
                                  <span className="subheading">Furnish Type</span>: {
                                    (ele?.adId?.furnishingTypes && ele?.adId.furnishingTypes.length > 0 ? 
                                      capitalizeFirstLetter(
                                        ele?.adId.furnishingTypes
                                          .map((furnishType) => furnishType?.furnishingType)
                                          .join("  ")
                                          .replace(/-/g, ' ') 
                                      )
                                      : 
                                      "N/A"
                                    )
                                  }
                                </li>
                                  <li><span className="subheading">Listing Type</span>: {ele?.adId?.userType === ownerType ? 'Room' : 'Tenant'}</li>
                                  {/* <li><span className="subheading">User Type</span>: {ele?.adId?.userType}</li> */}
                              </ul>
                            </div>
                          </div>
                          {/* find more column desktop */}
                          {isDesktop && (<div className="col-12 col-md-3 mt-2 mb-3 mb-md-0 findmore">
                            <div className="row">
                              <div className="col-6 col-md-12 my-2 my-md-0">
                                <Link to={(ele?.adId?.userType === ownerType) ? ("/ownerproduct/" + ele?.adId._id) : ("/tenantproduct/" + ele?.adId._id)}>
                                  <button
                                    type="button"
                                    className="main-btn"
                                  >
                                    Find more...
                                  </button>
                                </Link>
                              </div>
                              <div className="col-6 col-md-12 text-start py-3 mt-md-5 py-md-3 mx-md-3">
                                {ele?.adId?.userId?.membershipStatus && isMembershipEnabled ? 
                                  <img src="/images/placeholder/premium.png" height={40} width={100} alt='premium-member' />:''}
                              </div>
                            </div>
                            <div className="col-6 col-md-12  py-3 mt-md-5 my-md-0">
                                <ShareAndSaveButton IsFavorite={IsFavorite} ownerData={ele?.adId}/>
                            </div>

                          </div>)}

                          {/* find more column mobile */}
                          {isMobile && (<div className="row">
                              <div className="d-flex justify-content-start col-8 mb-2">
                                <Link to={(ele?.adId?.userType === ownerType) ? ("/ownerproduct/" + ele?.adId._id) : ("/tenantproduct/" + ele?.adId._id)}>
                                  <button
                                    type="button"
                                    className="main-btn"
                                  >
                                    Find more...
                                  </button>
                                </Link>
                              </div>
                              <div className={isMembershipEnabled ? "col-4 d-flex justify-content-end" : "d-none"}>
                                {ele?.adId?.userId?.membershipStatus && isMembershipEnabled ? 
                                  <img src="/images/placeholder/premium.png" height={40} width={100} alt='premium-member' />:''}
                              </div>
                            <div className={isMembershipEnabled ? "col-4 d-flex justify-content-start pt-4" : "col-4 d-flex justify-content-end pt-4"}>
                                <ShareAndSaveButton IsFavorite={IsFavorite} ownerData={ele?.adId}/>
                            </div>

                          </div>)}
                        </div>
                      </>
                    );
                  })}

                </>))}
              </div>

              {/* --------  pagination------- */}
                <div className="row mb-2">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className={"page-link " + (page <= 1 ? 'disabled' : '')} href={null} aria-label="Previous" onClick={()=>{setPage(page - 1)}}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>

                      </a>
                    </li>
                    {totalCount > 0 && [...Array(Math.ceil(totalCount/limit) ).keys()].slice(0,3).filter((pgNumber, i) => {
                    
                      if(pgNumber + page < Math.ceil(totalCount/limit) +1){
                        return true;
                      }
                      

                    }).map((pgNumber)=>{
                      return <li key={pgNumber + page }
                          className={`page-item ${page === pgNumber + page  ? 'active' : ''} `} >

                          <a onClick={() => setPage(pgNumber + page)}
                            className='page-link'
                            href={null}>

                            {pgNumber + page}
                          </a>
                        </li>
                    })}

                    <li className="page-item">
                      <a className={"page-link " + (pageData?.length < limit ? 'disabled' : '')} href={null} aria-label="Next" onClick={()=> setPage(page + 1)} >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default FavoriteAndContacted;





